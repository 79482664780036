import { useState } from "react";

import '../../styles/assessment.css';
import apiCall from "../utilities/auth/apiCall";
import { assessmentStart, assessmentUpdate } from "../utilities/auth/authApi";
import alertMessage from "../utilities/alertMessage";
import { reportError } from "../../functions/sentry";

import AssessmentCode from "./code/assessmentCode";
import AssessmentStart from "./start/assessmentStart";
import AssessmentRead from "./read/assessmentRead";
import AssessmentConversation from "./conversation/assessmentConversation";
import AssessmentOpinion from "./opinion/assessmentOpinion";
import AssessmentScore from "./score/assessmentScore";


function AssessmentScreen() {

  // Define variables
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [config, setConfig] = useState({});
  const [section, setSection] = useState(0); // 0 - code, 1 - start, 2 - read, 3- conversation, 4 - opinion, 5 - results
  const [instructions, setInstructions] = useState(null);


  // Define functions
  function handleSetCode(value) {
    setCode(value);
    if( value.length > 8 ) {
      setButtonDisabled(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    setButtonDisabled(true);
    setLoading(true);

    // Simple validation
    if( code.length != 12 ) {
      alertMessage('This is not a valid code. Please try again. You can contact support@flowspeak.io if you need assistance.', 'error');
      setTimeout(() => {
        setButtonDisabled(false);
        setLoading(false);
      }, 2000);
      return;
    }

    // Validate the assessment code
    apiCall(assessmentStart, {code: code}).then((response) => {
      if( response.success ) {
        setConfig(response.data);
        setSection(1);
      } else {
        alertMessage(response.error, 'error');
        reportError(response.error)
      }
    }).finally(() => {
      setTimeout(() => {
        setButtonDisabled(false);
        setLoading(false);
      }, 2000);
    });

  }

  function handleSteps(data) {
    const payload = {code: config.code};

    if( data.section ) {
      setSection(data.section);
      payload.section = data.section;
    }

    if( data.step || data?.step?.toString() === '0' ) { payload.step = data.step }
    if( data.passageStep ) { payload.passageStep = data.passageStep }
    if( data.conversationStep ) { payload.conversationStep = data.conversationStep }
    if( data.questionStep ) { payload.questionStep = data.questionStep }

    apiCall(assessmentUpdate, payload).then((response) => {
      if( response.error ) {
        reportError(response.error);
        alertMessage(response.error, 'error');
      }
    });
  }


  // Screen components
  let bodyContent;

  if( section === 0 ){
    bodyContent = <AssessmentCode handleSubmit={handleSubmit} code={code} handleSetCode={handleSetCode} buttonDisabled={buttonDisabled} loading={loading} />;
  } else if ( section === 1 ) {
    bodyContent = <AssessmentStart config={config} handleSteps={handleSteps} setSection={setSection} setInstructions={setInstructions} instructions={instructions} />;
  } else if ( section === 2 ) {
    bodyContent = <AssessmentRead config={config} handleSteps={handleSteps} instructions={instructions.read} />;
  } else if ( section === 3 ) {
    bodyContent = <AssessmentConversation config={config} handleSteps={handleSteps} instructions={instructions.conversation} />;
  } else if ( section === 4 ) {
    bodyContent = <AssessmentOpinion config={config} handleSteps={handleSteps} instructions={instructions.opinion} />;
  } else if ( section === 5 ) {
    bodyContent = <AssessmentScore config={config} instructions={instructions.scoring} />;
  }


  return (
    <div class='flex-column assess-wrapper'>
      <div class='flex-column assess-continer'>
        {bodyContent}
      </div>
    </div>
  )
}

export default AssessmentScreen;