import React from "react";
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from "react-router-dom";

import { Toaster } from 'react-hot-toast';

import { reportError } from "./functions/sentry";
import { getProfile, getUpdatedScore } from "./components/utilities/auth/authApi";
import { isAuthed } from "./components/utilities/auth/tokenHelpers";
import { Mixpanel } from './components/utilities/analytics/mixpanelHelpers';
import loadCustomCss from "./functions/loadCustomCss";

import PrivateRoute from "./components/utilities/auth/privateRoute";

import NotFound from "./components/start/notFound";
import Start from "./components/start";

import CatalogScreen from "./components/catalog";
import AssessmentScreen from "./components/assessment";

import WelcomeChoosePathway from "./components/start/welcomeChoosePathway";
import WelcomeChooseTopics from "./components/start/welcomeChooseTopics";
import WelcomeSetReminders from "./components/start/welcomeSetReminders";

import Welcome from "./components/start/welcome";
import WelcomeChooseMembership from "./components/start/welcomechoosemembership";
import WelcomePurchase from "./components/start/welcomepurchase";
import WelcomePurchaseConfirmation from "./components/start/welcomepurchaseconfirmation";
import WelcomeChoosePackage from "./components/start/welcomechoosepackage";
import WelcomeTutorial from  "./components/start/welcometutorial";
import VerifyAccount from "./components/start/verifyaccount";

import ResetPassword from "./components/start/resetpassword";
import ForgotPasswordReset from "./components/start/forgotPasswordReset";
import SetPassword from "./components/start/setpassword";
import SetPasswordUnhas from "./components/start/setPasswordUnhas";
import SetPasswordAMINEF from "./components/start/setPasswordAMINEF";
import ActivateScreen from "./components/start/activate";

import Dashboard from "./components/dashboard";

import Lesson from "./components/lesson/show";
import LessonRelatedContent from "./components/lesson/related";
import LessonCommunity from "./components/lesson/community";

import Bite from "./components/bite/show";

import ListeningLesson from './components/listeninglesson/show';


import ExpressionScreen from "./components/expression";

import AiTalkScreen from "./components/aitalk";

import BoosterScreen from "./components/booster";

import Community from "./components/community";
import ViewPost from "./components/community/view";

import Explore from "./components/explore";

import Saved from "./components/saved";

import Chat from "./components/chat";

import Courses from "./components/course"

import Notifications from "./components/notifications"

import Profile from "./components/profile";
import Preferences from "./components/profile/preferences";
import ProfileSubscription from "./components/profile/subscription";
import CertificateName from "./components/profile/certificateName";

import Progress from "./components/progress";
import ProgressLesson from "./components/progress/progressLesson";
import ProgressLessons from "./components/progress/progressLessons";
import ProgressStats from "./components/progress/progressStats";
import BadgeScreen from "./components/progress/badgesScreen";

import Suggestions from "./components/suggestions";

import Packages from "./components/packages";
import PackagesReview from "./components/packages/review";
import PackagesPurchase from "./components/packages/purchase";
import PackagesComplete from "./components/packages/complete";

import Membership from "./components/packages/membership";

import MicrophoneAccess from "./components/help/microphoneaccess";

import Organization from "./components/organization";

import OrgStart from "./components/orgstart";
import OrgRegister from "./components/orgstart/orgRegister";
import OrgLanding from "./components/orgstart/orgLanding";

import LaunchScreen from "./components/launch";

import ChallengeScreen from "./components/challenge";


function App() {

  return (
    <BrowserRouter>
      <Toaster />
      <RoutesComponent />
    </BrowserRouter>
  );
}


function RoutesComponent() {

  const profileDataLoaded = useSelector((state) => state.profile.initialized);

  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;


  useEffect(() => {
    trackPageView(); // To track the first pageview upon load

    if( !profileDataLoaded ) {
      getProfileInfo();
    }

  }, [pathname]);


  function trackPageView() {

    // Also try to get profile info here
    // Sometimes the auto login skips the get profile step
    if( !profileDataLoaded ) {
      getProfileInfo();
    }

    const path = window.location.pathname;
    let baseTitle = path.slice(1).replace('/', ' ').replace('-', ' ');
    let title = baseTitle.charAt(0).toUpperCase() + baseTitle.slice(1);

    if( title === '') { title = 'Dashboard' }
    if( path.includes('lesson')) { title = 'Lesson' }
    if( path.includes('lesson') && path.includes('community')) { title = 'Lesson community' }
    if( path.includes('lesson') && path.includes('responses') ) { title = 'Lesson community responses' }
    if( path.includes('lesson') && path.includes('comments') ) { title = 'Lesson community response comments' }

    if( path.includes('/progress/lessons') ) { title = 'Progress lessons' }


    if( path.includes('community') && path.includes('comments') ) { title = 'Community post comments' }
    if( path.includes('community') && path.includes('responses') ) { title = 'Community response comments' }

    if( path.includes('bites') ) { title = 'Bite Lesson' }
    if( path.includes('listening') ) { title = 'Listening Lesson' }

    if( path.includes('aitalks') ) { title = 'AI Talk' }

    const mixpanelTitle = "Page: " + title;
    Mixpanel.track(mixpanelTitle);

    // Update the page title
    document.title = `${title} | FLOW Speak`;


    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return;
    }

  }


  function getProfileInfo() {

    const isLoggedIn = isAuthed();
    if( !isLoggedIn ) { return }

    getProfile().then((response) => {
      if( response.data ) {
        response.data.initialized = true;

        // Update the redux store
        dispatch({ type: 'initializeProfile', payload: response.data });

        if( response.data.externalCssUrl ) {
          loadCustomCss(response.data.externalCssUrl);
        }

        // Set the local storage values
        // TO DO - move this to redux
        if( response.data.nativeLanguage ) {
          localStorage.setItem("nativeLanguageCode", response.data.nativeLanguage);
        }

        if( response.data.autoplayAudio ) {
          localStorage.setItem("autoplayAudio", response.data.autoplayAudio);
        }

        Mixpanel.identify(response.data.id);
        Mixpanel.people.set({
          $first_name: response.data.firstName
        });

        if( window.hj && process.env.NODE_ENV === 'production' ) {
          window.hj('identify', response.data.id, {
            first_name: response.data.firstName
          });
        }

      }
    }).catch((error) => {
      reportError(error);
    });

    // Also get the score info
    getUpdatedScore().then((response) => {
      if( response.data ) {
        // Update the redux store
        dispatch({ type: 'setScore', payload: response.data });
      }
    }).catch((error) => {
      reportError(error);
    });
  }


  return (
    <Routes>

      <Route path="/start" element={<Start />} />
      <Route path="/reset_password" element={<ResetPassword />} />
      <Route path="/forgot_password" element={<ForgotPasswordReset />} />
      <Route path="/set_password/:accountCode" element={<SetPassword />} />
      <Route path="/set_password" element={<SetPassword />} />
      <Route path="/register" element={<Start />} />
      <Route path="/signup" element={<Start />} />
      <Route path="/login" element={<Start defaultLocation='login' />} />

      <Route path="/activate_account/:accountCode" element={<SetPasswordUnhas />} />
      <Route path="/activate_account" element={<SetPasswordUnhas />} />

      <Route path="/activate_account_aminef/:accountCode" element={<SetPasswordAMINEF />} />
      <Route path="/activate_account_aminef" element={<SetPasswordAMINEF />} />

      <Route path="/activate/:organizationSlug/:accountCodeInitial" element={<ActivateScreen />} />
      <Route path="/activate/:organizationSlug" element={<ActivateScreen />} />
      <Route path="/activate" element={<ActivateScreen />} />

      <Route path="/lesson-catalog" element={<CatalogScreen />} />
      <Route path="/assessment/:slug" element={<AssessmentScreen />} />
      <Route path="/assessment" element={<AssessmentScreen />} />


      <Route path="/jsaf" element={<OrgStart />} />

      <Route path="/register/:slug/welcome" element={<PrivateRoute component={Welcome} />} />
      <Route path="/register/:slug/choose-membership" element={<PrivateRoute component={WelcomeChooseMembership} />} />
      <Route path="/register/:slug/purchase" element={<PrivateRoute component={WelcomePurchase} />} />

      <Route path="/register/:slug/purchase-confirmation" element={<PrivateRoute component={WelcomePurchaseConfirmation} />} />
      <Route path="/register/:slug/complete" element={<PrivateRoute component={OrgLanding} />} />
      <Route path="/register/:slug" element={<OrgRegister />} />

      {/* Used for embedding into other systems */}
      <Route path="/launch/:slug" element={<LaunchScreen />} />

      {/* --- Onboarding screens --- */}

      <Route path="/welcome-choose-pathway" element={<PrivateRoute component={WelcomeChoosePathway} />} />
      <Route path="/welcome-choose-topics" element={<PrivateRoute component={WelcomeChooseTopics} />} />
      <Route path="/welcome-choose-topics/:lookupProfile" element={<PrivateRoute component={WelcomeChooseTopics} />} />
      <Route path="/welcome-set-reminders" element={<PrivateRoute component={WelcomeSetReminders} />} />

      <Route path="/welcome" element={<PrivateRoute component={Welcome} />} />
      <Route path="/welcome-choose-membership" element={<PrivateRoute component={WelcomeChooseMembership} />} />
      <Route path="/welcome-purchase" element={<PrivateRoute component={WelcomePurchase} />} />
      <Route path="/welcome-purchase-confirmation" element={<PrivateRoute component={WelcomePurchaseConfirmation} />} />
      <Route path="/welcome-choose-package" element={<PrivateRoute component={WelcomeChoosePackage} />} />
      <Route path="/welcome-mic-test" element={<PrivateRoute component={WelcomeTutorial} />} />
      <Route path="/verify-account" element={<PrivateRoute component={VerifyAccount} />} />

      {/* --- Challenge screens --- */}
      <Route path="/challenge" element={<PrivateRoute component={ChallengeScreen} />} />

      {/* --- Profile screens --- */}
      <Route path="/profile/certificate-name" element={<PrivateRoute component={CertificateName} />} />
      <Route path="/profile/subscription" element={<PrivateRoute component={ProfileSubscription} />} />
      <Route path="/profile/preferences" element={<PrivateRoute component={Preferences} />} />
      <Route path="/profile" element={<PrivateRoute component={Profile} />} />

      {/* --- Progress screens --- */}
      <Route path="/progress/lessons/:id" element={<PrivateRoute component={ProgressLesson} />} />
      <Route path="/progress/lessons" element={<PrivateRoute component={ProgressLessons} />} />
      <Route path="/progress/stats" element={<PrivateRoute component={ProgressStats} />} />
      <Route path="/progress/:params_public_profile_id" element={<PrivateRoute component={Progress} />} />
      <Route path="/progress" element={<PrivateRoute component={Progress} />} />
      <Route path="/badges" element={<PrivateRoute component={BadgeScreen} />} />

      {/* --- Lessons --- */}
      <Route path="/lessons/:id/community/:moduleContentId/responses/:responseId/comments" element={<PrivateRoute component={LessonCommunity} />} />
      <Route path="/lessons/:id/community/:moduleContentId/responses" element={<PrivateRoute component={LessonCommunity} />} />
      <Route path="/lessons/:id/community" element={<PrivateRoute component={LessonCommunity} />} />
      <Route path="/lessons/:id/related" element={<PrivateRoute component={LessonRelatedContent} />} />
      <Route path="/lessons/:id" element={<PrivateRoute component={Lesson} />} />

      {/* --- Bites --- */}
      <Route path="/bites/:id" element={<PrivateRoute component={Bite} />} />


      {/* --- Listening Lessons --- */}
      <Route path="/listening/:id" element={<PrivateRoute component={ListeningLesson} />} />

      {/* --- AI Talk Lessons --- */}
      <Route path="/aitalks/:id" element={<PrivateRoute component={AiTalkScreen} />} />

      {/* --- Booster Lessons --- */}
      <Route path="/booster/:id" element={<PrivateRoute component={BoosterScreen} />} />

      {/* --- Expression Lessons --- */}
      <Route path="/expressions/:id" element={<PrivateRoute component={ExpressionScreen} />} />


      {/* --- Community --- */}
      <Route path="/community/responses/:id/comments" element={<PrivateRoute component={ViewPost} />} />
      <Route path="/community/posts/:id/comments" element={<PrivateRoute component={ViewPost} />} />
      <Route path="/community" element={<PrivateRoute component={Community} />} />
      <Route path="/community" element={<PrivateRoute component={Community} />} />

      {/* --- Explore --- */}
      <Route path="/explore" element={<PrivateRoute component={Explore} />} />


      {/* --- Saved --- */}
      <Route path="/saved" element={<PrivateRoute component={Saved} />} />


      {/* --- Chat --- */}
      <Route path="/live-chat" element={<PrivateRoute component={Chat} />} />


      {/* --- Course --- */}
      <Route path="/courses" element={<PrivateRoute component={Courses} />} />


      {/* --- Notifications --- */}
      <Route path="/notifications" element={<PrivateRoute component={Notifications} />} />


      {/* --- Suggestions --- */}
      <Route path="/help" element={<PrivateRoute component={Suggestions} />} />

      {/* --- Packages --- */}
      <Route path="/packages/review" element={<PrivateRoute component={PackagesReview} />} />
      <Route path="/packages/purchase" element={<PrivateRoute component={PackagesPurchase} />} />
      <Route path="/packages/complete" element={<PrivateRoute component={PackagesComplete} />} />
      <Route path="/packages" element={<PrivateRoute component={Packages} />} />
      <Route path="/membership" element={<PrivateRoute component={Membership} />} />

      {/* --- Organization --- */}
      <Route path="/organization" element={<PrivateRoute component={Organization} />} />

      {/* --- Help / Mic --- */}
      <Route path="/help/microphone" element={<PrivateRoute component={MicrophoneAccess} />} />


      {/* --- Dashboard --- */}
      <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
      <Route path="/" element={<PrivateRoute component={Dashboard} />} />

      <Route path="*" element={<NotFound />} />

    </Routes>
  );
}

export default App;
