import { useState } from "react";

import RecordResponse from "../../utilities/recordresponse";
import ButtonSubmitRound from "../../utilities/forms/buttonSubmitRound";
import ProgressDots from "../../utilities/progressDots";
import StartHeader from "./startHeader";


function AssessmentMic(props) {

  // Define variables
  const [status, setStatus] = useState(0); // 1 - start, 2 - success, 3 - error
  const [errorText, setErrorText] = useState(null);
  const logProgress = props.logProgress;
  const instructions = props.instructions;
  const organizationLogo = props.organizationLogo;


  // Define functions
  function handleClick() {
    if( status === 0 ) {
      setStatus(1);
    } else if( status === 3 ) {
      setStatus(1);
      setErrorText(null);
    }
  }

  function handleError(error) {
    const errorString = error.toString();

    if( errorString === 'No speech recognized' ) {
      setErrorText(<p class='access-error-message'>Sorry, we could not hear you.<br />Please try again.</p>);
    }

    setStatus(3);
  }

  function handleTranscript(transcript, audioBlobUrl, blob, responseObject) {
    if( responseObject.success ) {
      setStatus(2);
    } else {
      setStatus(3);
    }
  }


  // Screen components
  let instruction = instructions.check.body;
  let instructionTranslation = instructions.check.translation;
  let sayWords = instructions.check.words;
  let instructionsExtra;
  let nextStepElement = (
    <ButtonSubmitRound
      value={"Continue"}
      disabled={true}
    />
  );

  if( status === 1 ) {
    instructionsExtra = 'Tap to stop recording';
  } else if( status === 2 ) {
    instruction = instructions.next.body;
    instructionTranslation = instructions.next.translation;
    sayWords = '';
    instructionsExtra = '';

    nextStepElement = (
      <ButtonSubmitRound
        value={"Continue"}
        handleButtonClick={() => logProgress({step: 5})}
      />
    );

  } else if( status === 3 ) {
    // instruction = '';
    // instructionTranslation = '';
    // sayWords = '';
    // instructionsExtra = '';
  }


  return (
    <>
      <StartHeader defaultOrgLogo={organizationLogo} />
      <div class='flex-column assess-glass-extra'>

        <div class='flex-column' style={{width: '100%', marginBottom: '30px'}}>
          <h2>Let's check your microphone</h2>

          <div class='assess-mic-test-container' onClick={() => handleClick()}>
            <RecordResponse
              handleTranscript={handleTranscript}
              providedText={instructions.check.words}
              scorePronunciation={true}
              colorScheme={'big'}
              retryCount={0}
              extras={{}}
              handleError={handleError}
            />
            <div class='assess-mic-extra-text'>
              {instructionsExtra}
              {errorText}
            </div>
          </div>

          <br />
          <div class='flex-column'>
            <p class='has-text-centered'>
              {instruction}
              <br />
              {sayWords}
            </p>
            <p class='translation-p has-text-centered'>{instructionTranslation}<br />{sayWords}</p>
          </div>
        </div>

        <div class='flex-column'>
          {nextStepElement}

          <div class='assess-dots-container'>
            <ProgressDots index={3} dots={5} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AssessmentMic;