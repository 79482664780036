import CefrSubBar from "../helpers/cefrSubBar";
import ScoreReportEmail from "./scoreEmailReport";


function ScoreResults(props) {

  // Define variables
  const code = props.code;
  const score = props.score;
  const cefrLevel = score.cefrLevel;
  const scoresByType = score.scoresByType;
  const testTakerDetails = score.testTakerDetails;


  return (
    <div style={{width: '100%'}}>

      <div class='flex-column assess-score-section'>
        <p class='assess-fancy'>Your English speaking level is</p>
        <p class='assess-cefr-level'>{cefrLevel}</p>
      </div>

      <div class='flex-column assess-score-section'>
        <p class='assess-fancy'>Sub-level</p>
        <CefrSubBar scoreObject={scoresByType.overall} />
      </div>

      <div class='flex-column assess-score-section'>
        <p class='assess-fancy'>Score breakdown</p>
        <br />

        <CefrSubBar scoreObject={scoresByType.pronunciation} title={'Pronunciation'} />
        <CefrSubBar scoreObject={scoresByType.grammar} title={'Grammar'} />
        <CefrSubBar scoreObject={scoresByType.vocabulary} title={'Vocabulary'} />
        <CefrSubBar scoreObject={scoresByType.fluency} title={'Fluency'} />
        <CefrSubBar scoreObject={scoresByType.relevance} title={'Relevance'} />
      </div>

      <div class='flex-column assess-score-section'>
        <p class='assess-fancy'>Test taker details</p>

        <div class='flex-column assess-test-taker-details'>
          <p class='assess-test-taker-name'>{testTakerDetails.firstName} {testTakerDetails.lastName}</p>
          <p>{testTakerDetails.email}</p>
          <p>Code: {testTakerDetails.assessmentIdentifer}</p>
        </div>

        <ScoreReportEmail code={code} />
      </div>

    </div>
  );
}

export default ScoreResults;