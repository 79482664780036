import { useState, useEffect } from "react";

import apiCall from "../../utilities/auth/apiCall";
import { assessmentGenerateScore } from "../../utilities/auth/authApi";
import LoadingBasic from "../../utilities/loadingbasic";
import alertMessage from "../../utilities/alertMessage";
import ScoreResults from "./scoreResults";


function AssessmentScore(props) {

  // Define variables
  const [step, setStep] = useState(0);
  const [score, setScore] = useState(null);
  const config = props.config;
  const instructions = props.instructions;


  // Component load actions
  useEffect(() => {
    apiCall(assessmentGenerateScore, {code: config.code}).then((response) => {
      if( response.success ) {
        setScore(response.data);
        setTimeout(() => {
          setStep(1);
        }, 1500);
      } else {
        alertMessage(response.error, 'error');
      }
    });
  }, []);


  // Define functions


  // Screen components
  let headingContent;
  let bodyContent;

  if( step === 0 ) {
    headingContent = (
      <div class='flex-column assess-score-waiting-header'>
        <p class='assess-fancy'>{instructions.subheading}</p>
        <h2>{instructions.heading}</h2>
      </div>
    );

    bodyContent = <LoadingBasic />;

  } else if( step === 1 ) {

    headingContent = <h1>Results</h1>;

    bodyContent = (
      <ScoreResults score={score} code={config.code} />
    );
  }


  return (
    <>
      {headingContent}
      <br />
      <div class='flex-column assess-glass'>
        {bodyContent}
      </div>
    </>
  );
}

export default AssessmentScore;