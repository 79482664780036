import { useState, useEffect } from "react";

import alertMessage from "../../utilities/alertMessage";
import RenderHtmlText from "../../utilities/renderhtmltext";
import Review from "../helpers/review";
import AvatarWithAudio from "../../utilities/avatars/avatarWithAudio";
import AssessmentRecord from "../helpers/assessmentRecord";
import LoadingBasic from "../../utilities/loadingbasic";


function ReadPassage(props) {

  // Define variables
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('record'); // record, review
  const [retryCount, setRetryCount] = useState(0);
  const [responseData, setResponseData] = useState({});
  const cefrLevel = props.cefrLevel;
  const passage = props.passage;
  const instructionRecord = props.instructionRecord;
  const instructionExtra = props.instructionExtra;
  const instructionReview = props.instructionReview;
  const timerSpeak = props.timerSpeak;
  const timerReview = props.timerReview;
  const nextAction = props.nextAction;
  const retryPossible = props.retryPossible;
  const scoreType = props.scoreType;


  // Component load actions
  useEffect(() => {

    setLoading(true);
    setStatus('record');
    setResponseData({});
    setRetryCount(0);

    if( passage ) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }

  }, [passage]);


  // Define functions
  function handleTranscript(responseObject) {
    if( responseObject.success ) {

      // Add the retry count
      responseObject.retryCount = retryCount;

      setResponseData(responseObject);
      setStatus('review');
    } else {
      if( responseObject.error === 1001 ) {

        // Handle empty object
        const emptyObject = {
          success: true,
          learnerDidNotRecord: true,
        }

        setResponseData(emptyObject);
        setStatus('review');

      } else {
        reportError(`Assessment Read error: ${JSON.stringify(responseObject)}`);
        alertMessage('Sorry, there was an error. Please contact support@flowspeak.io for assistance.');
      }
    }
  }

  function retry() {
    setStatus('record');
    setResponseData({});
    setRetryCount(1);
  }


  // Screen components
  let passageContent = <LoadingBasic />;
  let actionContent;

  if( !loading ) {

    passageContent = (
      <div class='flex-column assess-passage-container'>
        <AvatarWithAudio
          type='assistant'
          avatarUrl={passage.iconUrl}
          audioUrl={passage.audioUrl}
        />
        <br />
        <RenderHtmlText string={passage.text} />
      </div>
    );

    if( status === 'record' ) {

      let instructionExtraElement;
      if( instructionExtra ) {
        instructionExtraElement = <RenderHtmlText string={instructionExtra} />;
      }

      const messages = [{
        role: "assistant",
        content: passage.text
      }]

      actionContent = (
        <div class='flex-column assess-record-container'>
          <p class='assess-fancy'>{instructionRecord}</p>
          {instructionExtraElement}
          <AssessmentRecord
            messages={messages}
            handleTranscript={handleTranscript}
            providedText={passage.text}
            scoreType={scoreType}
            cefrLevel={cefrLevel}
            seconds={timerSpeak}
          />
        </div>
      )

    } else if( status === 'review' ) {
      actionContent = (
        <Review
          instruction={instructionReview}
          audioUrl={responseData.audioUrl}
          buttonFunction={() => nextAction(responseData)}
          seconds={timerReview}
          retryCount={retryCount}
          retryPossible={retryPossible}
          retry={retry}
        />
      );
    }
  }


  return (
    <>
      <div class='flex-column assess-glass'>
        {passageContent}
        {actionContent}
      </div>
    </>
  );
}

export default ReadPassage;